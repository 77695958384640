<template>
  <ContainerBasic :id="listId">
    <ContainerBasic container-type="PAGE">
      <div v-if="!error" ref="container">
        <!-- top panel -->
        <div
          v-show="!hideListHeader"
          class="sticky bg-white md:bg-transparent top-stickyheader md:top-0 md:static z-[521]"
        >
          <div
            class="flex flex-col w-full grid-cols-12 md:bg-transparent md:grid gap-sm md:gap-md"
            :class="layoutBGColor ? layoutBGColor : 'bg-white'"
          >
            <div
              class="flex flex-row items-center justify-between md:col-span-8"
              :class="{ 'md:!col-span-12': sortingOptions.length <= 1 }"
            >
              <div
                :class="[
                  'text-sm text-white bg-activesection-base  p-xs text-center w-full',
                  'md:text-border-dark md:p-0 md:bg-transparent md:text-left md:w-auto',
                ]"
              >
                <slot
                  v-if="$slots.totalEntriesTranslationKey"
                  name="totalEntriesTranslationKey"
                  :entry-count="entryCount"
                />
                <i18n-t
                  v-else-if="!hideEntryCount"
                  keypath="advancedList.entryCount"
                  :plural="entryCount"
                >
                  <template #counter>
                    <span class="font-bold"> {{ entryCount }}</span>
                  </template>
                  <template #name>
                    <span class="font-bold">{{
                      t(totalEntriesTranslationKey, { count: entryCount })
                    }}</span>
                  </template>
                </i18n-t>
              </div>
              <div
                v-if="hasFilterPanel"
                class="text-sm cursor-pointer text-primary-base"
              >
                <div
                  class="hidden md:ml-md mr-xs md:block"
                  @click="resetFilters(true)"
                >
                  <FaIcon icon-class="fas fa-arrow-rotate-left" />
                  {{ t('andvancedList.filterUndo') }}
                </div>
              </div>
            </div>

            <div
              v-if="sortingOptions.length > 1"
              class="flex items-center justify-between col-span-4 px-0 md:p-0 pb-sx md:pb-0 mx-sm md:mx-0"
            >
              <Dropdown
                v-if="sortingOptions.length > 1"
                v-model="orderBy"
                :options="sortingOptions"
                classes="!w-[calc(100%-65px)] md:!w-full"
                :add-default="false"
                :hide-option-if-unavailable="hideSortingOptionsIfUnavailable"
              />
              <BasicButtonIcon
                v-if="hasFilterPanel"
                icon="fas fa-sliders"
                classes="my-sm block !ml-sm md:hidden h-[38px]"
                button-classes="flex items-center justify-center h-[38px] rounded-alt-md !bg-primary-base text-white custom-button-icon"
                @click="toggleFilterPanel()"
              />
            </div>
          </div>
        </div>
        <div class="z-[550]">
          <template v-if="$slots.filters">
            <slot
              name="filters"
              :filters="filters"
              :filter-options="filterOptions"
              :change-filter="changeFilter"
              :reset-filters="resetFilters"
              :reset-filters-ping="resetFiltersPing"
            />
          </template>
        </div>

        <template v-if="$slots['result-header']">
          <slot name="result-header" />
        </template>

        <!-- Results -->
        <div class="md:mt-0">
          <div :id="resultContainerId">
            <ContainerBasic v-if="entries?.length && !isLoading">
              <slot
                name="entries"
                :entries="entries"
                :filters="combinedSettings"
                :reload="reload"
              />
            </ContainerBasic>

            <slot
              v-else-if="!entries?.length && !isLoading"
              name="no-entries"
              :filters="combinedSettings"
              :change-filter="changeFilter"
              :has-filters="Object.keys(filters).length > 0"
            />

            <ContainerBasic v-else container-type="PAGE" class="py-sm">
              <slot name="loader" />
            </ContainerBasic>
          </div>

          <ContainerBasic v-if="pages > 1" :classes="'py-lg'">
            <Pagination v-model="page" :pages="pages" />
          </ContainerBasic>
        </div>
      </div>
      <div v-else><LoadingError /></div>
    </ContainerBasic>
  </ContainerBasic>
</template>
<script setup lang="ts">
import Pagination from './components/pagination/pagination.vue';
import ContainerBasic from '@/components/components/container-basic.vue';
import {
  useAdvancedList,
  type AdvancedListConfig,
  type AdvancedListInitialData,
  type Filters,
} from './useAdvancedList';
import Dropdown from './components/dropdown/dropdown.vue';
import useSetToUrlQuery from './composables/useSetToUrlQuery';
import FaIcon from '@/components/fa-icon.vue';
import BasicButtonIcon from '@/components/components/core/basicButtonIcon/basic-button-icon.vue';
import { useNav } from '~/stores/useNav';
import { LoadingError } from '@/complib';

const props = defineProps({
  config: {
    type: Object as PropType<AdvancedListConfig>,
    required: true,
  },
  initialData: {
    type: Object as PropType<AdvancedListInitialData>,
    required: false,
    default: () => null,
  },
  initialFilters: {
    type: Object as PropType<Filters>,
    required: false,
    default: () => null,
  },
  stateInUrl: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasFilterPanel: {
    type: Boolean,
    required: false,
    default: false,
  },
  totalEntriesTranslationKey: {
    type: String,
    required: false,
    default: 'andvancedList.entry',
  },
  /**
   * Reloads the list when the value changes
   *
   * use composable useListReloadPing to set the value
   */
  reloadPing: {
    type: Boolean,
    required: false,
    default: false,
  },
  /**
   * re initializes the list
   *
   * use composable useListReloadPing to set the value
   */
  reInitPing: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideSortingOptionsIfUnavailable: {
    type: Boolean,
    required: false,
    default: false,
  },
  /**
   * Hides the list header (sorting, filter, entry count)
   */
  hideListHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideEntryCount: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits<{
  (e: 'toggleFilterPanel'): void;
}>();

const { t } = useTrans();
const layoutBGColor = useNav().layoutBGColor;
const configRef = toRef(props, 'config');

const {
  id,
  entries,
  isLoading,
  pages,
  page,
  entryCount,
  orderBy,
  sortingOptions,
  filters,
  filterOptions,
  combinedSettings,
  resetFiltersPing,
  resetFilters,
  reload,
  reInitialize,
  error,
} = await useAdvancedList(configRef, props.initialData, props.initialFilters);

const container = ref(null);

watch(
  () => props.reloadPing,
  async (nv) => nv && reload(),
);

watch(
  () => props.reInitPing,
  async (nv) => nv && reInitialize(),
);

//save state to url and load state from url
if (props.stateInUrl) {
  const urlParams = useSetToUrlQuery(combinedSettings);
  onMounted(() => {
    Object.keys(urlParams).forEach((cur) => {
      try {
        const value = JSON.parse(decodeURI(urlParams[cur] as string));
        if (cur === 'orderBy') orderBy.value = value;
        else filters[cur] = value;
      } catch {
        // Do nothing
      }
    });
  });
}
onMounted(() => {
  if (history.state?.filters) {
    try {
      const state = JSON.parse(history.state.filters);
      Object.keys(state).forEach((key) => {
        filters[key] = state[key];
      });
    } catch {
      // Do nothing
    }
  }
});

const changeFilter = (key: string, value: any) => {
  if (!value || value?.length === 0) {
    delete filters[key];
  } else {
    filters[key] = value;
  }
  history.replaceState(
    { ...history.state, filters: JSON.stringify(filters) },
    '',
  );
};

const listId = id;
const resultContainerId = 'result-container-' + id;

function toggleFilterPanel() {
  emits('toggleFilterPanel');
}
</script>
