<template>
  <div class="relative w-full col-span-12" :class="classes">
    <div
      ref="container"
      class="relative flex items-center justify-between bg-white cursor-pointer border-b-[3px] border-1 border-border-light top rounded-alt-sm h-input-h-md"
      :class="{ open: isOpen }"
      @click="onToggleContent"
    >
      <span class="truncate mx-xs"> {{ title }} </span>
      <div v-show="isOpen" class="relative pl-sm mr-xs">
        <FaIcon icon-class="fas fa-chevron-up" classes="" />
      </div>
      <div v-show="!isOpen" class="relative pl-sm mr-xs">
        <FaIcon icon-class="fas fa-chevron-down" classes="" />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="col-span-12 flex flex-col bg-white shadow-lg absolute select-none z-[110] mt-[-5px] w-full border-t-[3px] border-primary-light rounded-b-alt-sm"
      :class="{ ' max-h-[365px] overflow-y-scroll': needsScrollbar }"
    >
      <div
        v-for="(option, i) in options"
        :key="i"
        class="cursor-pointer entry px-xs py-xs"
        :class="{
          'bg-primary-base ease-in duration-300 text-white':
            isEntryActive(option),
          'text-grey-light hover:!bg-transparent !cursor-default':
            !option.isAvailable && !hideOptionIfUnavailable,
          hidden: !option.isAvailable && hideOptionIfUnavailable,
        }"
        @click="onToggleSelection(option)"
      >
        <label
          class="text-sm break-words"
          :class="{
            '!cursor-default': !option.isAvailable && !hideOptionIfUnavailable,
          }"
        >
          {{ t(option.label) }}</label
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { useDropdown, type DropdownOption } from './useDropdown';

const props = defineProps({
  options: {
    type: Array<DropdownOption>,
    requried: true,
    default: [],
  },
  addDefault: {
    type: Boolean,
    required: false,
    default: true,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  hideOptionIfUnavailable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useTrans();
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const {
  isOpen,
  onToggleContent,
  title,
  needsScrollbar,
  isEntryActive,
  onToggleSelection,
  options,
} = useDropdown(props, emit);

const container = ref();
onClickOutside(container, () => (isOpen.value = false));
</script>
<style scoped lang="postcss">
.entry:hover {
  @apply bg-activesection-alpha ease-in duration-300;
}

.open::after {
  content: '';
  @apply absolute;
}

.top > * {
  @apply py-2xs;
}

.entry > * {
  @apply cursor-pointer;
}
</style>
