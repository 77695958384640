export default function useSetToUrlQuery(value: Record<string, any>) {
  const params = useUrlSearchParams('history', {
    removeNullishValues: true,
  });

  watch(value, (newValue) => {
    Object.keys(params).forEach((cur) => delete params[cur]);

    Object.keys(newValue)
      .sort()
      .forEach(
        (cur) => (params[cur] = encodeURI(JSON.stringify(newValue[cur]))),
      );
  });

  return params;
}
