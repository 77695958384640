export type DropdownOption = {
  label: string;
  key: string;
  isAvailable?: boolean;
};

export const defaultKey = 'DEFAULT';

export function useDropdown(
  props: {
    modelValue: string | null;
    options: DropdownOption[];
    addDefault?: boolean;
  },
  emit: (event: 'update:modelValue', value: string | null) => void,
) {
  const { t } = useTrans();
  const defaultOption = {
    key: defaultKey,
    label: t('dropdown.all'),
    isAvailable: true,
  };

  const options = computed(() =>
    props.addDefault ? [defaultOption, ...props.options] : props.options,
  );
  const needsScrollbar = options.value.length > 7;

  const selectedItem = ref<DropdownOption | null>(
    props.modelValue
      ? options.value.find(({ key }) => key === props.modelValue) ?? null
      : props.addDefault
        ? options.value[0]
        : null,
  );

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue !== selectedItem.value.key) {
        selectedItem.value =
          options.value.find(({ key }) => key === newValue) ??
          (props.addDefault ? options.value[0] : null);
      }
    },
  );

  const isOpen = ref(false);

  const title = computed(() =>
    selectedItem.value ? t(selectedItem.value.label) : t('dropdown.default'),
  );

  function onToggleContent(): void {
    isOpen.value = !isOpen.value;
  }

  function onToggleSelection(newSelection: DropdownOption): void {
    if (!newSelection.isAvailable) {
      return;
    }
    selectedItem.value = newSelection;
    isOpen.value = false;
  }

  function isEntryActive(selection: DropdownOption) {
    if (!selectedItem.value) {
      return false;
    }
    return selection.key === selectedItem.value.key;
  }

  watch(selectedItem, (newValue) =>
    emit(
      'update:modelValue',
      newValue?.key === defaultKey ? null : newValue?.key ?? null,
    ),
  );

  return {
    isOpen,
    onToggleContent,
    title,
    needsScrollbar,
    isEntryActive,
    onToggleSelection,
    options,
  };
}
